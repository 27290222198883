import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Table from "react-bootstrap/Table"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SteamTopSellerForKeyseller from "../components/steamtopseller"
export const query = graphql`
  query($slug: String!) {
    keysellerJson(slug: { eq: $slug }) {
      title
      slug
      description
      url
      url_affiliate
      company
      headquarter
      country
      payments {
        name
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
const Keyseller = ({ data }) => {
  const keyseller = data.keysellerJson
  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO
        title={keyseller.title}
        keywords={[keyseller.title]}
        description={keyseller.description}
      />
      <Container className="text-left">
        <Row>
          <Col>
            <p className="keyseller">{keyseller.description}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Img
                fluid={keyseller.image.childImageSharp.fluid}
                alt={keyseller.title}
                style={{ float: "left", marginRight: "1rem", width: 100 }}
              />
              <h1>{keyseller.title}</h1>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th width="20%"></th>
                    <th width="80%"></th>
                  </tr>
                </thead>
                <tbody className="keyseller">
                  <tr>
                    <td>Name</td>
                    <td>{keyseller.title}</td>
                  </tr>
                  <tr>
                    <td>URL</td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={keyseller.url_affiliate}
                      >
                        {keyseller.url}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Firma</td>
                    <td>{keyseller.company}</td>
                  </tr>
                  <tr>
                    <td>Hauptsitz</td>
                    <td>
                      {keyseller.headquarter}, {keyseller.country}
                    </td>
                  </tr>
                  <tr>
                    <td>Zahlungsmethoden</td>
                    <td>
                      {keyseller.payments.map(item => (
                        <li key={item.id}>{item.name}</li>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SteamTopSellerForKeyseller
              keyseller={keyseller.slug}
            ></SteamTopSellerForKeyseller>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/keyseller">Zurück zur Keysellerübersicht</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default Keyseller
