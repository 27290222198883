import React, { Component, Fragment } from "react"
import axios from "axios"
import ResultList from "./gamekeys/resultlist"
const API_URL = "https://gamekeysearch.de/gamekeys/api"

class SteamTopSellerForKeyseller extends Component {
  constructor(props) {
    super(props)
    this.state = {
      steamTopSeller: [],
    }
  }

  getTopSeller = () => {
    let url = `${API_URL}/steamTopsellerForKeyseller/` + this.props.keyseller
    console.log(url)
    axios.get(url).then(res => {
      console.log("Ermittle TopSeller für " + this.props.keyseller)
      console.log(res.data)
      this.setState({ steamTopSeller: res.data })
      console.log("After setState:" + this.state.steamTopSeller)
    })
  }

  componentDidMount() {
    console.log("Ermittle TopSeller")
    this.getTopSeller()
  }

  render() {
    let results = this.state.steamTopSeller
    return (
      <Fragment>
        <h2>SteamTopSeller bei {this.props.keyseller}</h2>
        {results && <ResultList products={results} />}
      </Fragment>
    )
  }
}

export default SteamTopSellerForKeyseller
